import { ApisService } from 'src/app/services/apis.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'bootstrap'
import * as bootstrap from 'bootstrap';
import { DadosService } from 'src/app/services/dados.service';
import { Buttons, Privacidade } from 'src/app/models/language.model';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-privacidade',
  templateUrl: './privacidade.component.html',
  styleUrls: ['./privacidade.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacidadeComponent implements OnInit {

  lang: Privacidade = this.language.privacidade
  langButton: Buttons = this.language.buttons

  textMessageError = ''
  botaoAtivo = false;
  modalPolitica = false
  erroPrivacidade = false
  idModal: string = 'modalPolitica'
  modal: Modal | undefined
  textoPrivacidade = ''
  setChecked = false

  item = {
    privacidade: false
  }

  constructor(
    private router: Router,
    private dados: DadosService,
    private apiService: ApisService,
    private language: LanguageService
  ) { }

  ngOnInit(): void {

    this.recuperaDados()

  }

  getPrivacidade() {
    this.apiService.privacidade(this.dados.getDados().countryId).subscribe(
      res => {
        this.textoPrivacidade = res.contentData
        this.modal = new bootstrap.Modal(document.getElementById(this.idModal)!)
        this.modal.show()
        this.erroPrivacidade = false

        console.log(res)
      },
      err => {
        this.erroPrivacidade = true
        this.textMessageError = this.language.apiError(err.error)
        console.log(err)
      }
    )
  }

  proximaPagina() {

    this.router.navigate(['/cadastro-inicial']);

  }

  paginaAnterior() {
    this.router.navigate(['']);
  }

  mudancaCheck(ev: Event) {
    let statusCheck = ev.target as HTMLInputElement;

    statusCheck.checked ? this.botaoAtivo = true : this.botaoAtivo = false

    this.item.privacidade = statusCheck.checked

    this.dados.setDados(this.item)

  }

  openModal(e: Event) {
    e.preventDefault()

    this.getPrivacidade()

  }


  closeModalPolitica() {
    this.modalPolitica = false
  }

  recuperaDados() {
    if (!this.dados.hasDados()) return

    const sessionData = this.dados.getDados()
    const formField = Object.keys(this.item)

    formField.forEach(el => {

      if (el in sessionData) {
        this.setChecked = sessionData.privacidade
        this.setChecked ? this.botaoAtivo = true : this.botaoAtivo = false
      }
    })
  }
}
