
import { Component, isDevMode, OnInit } from '@angular/core'

declare const gtag: Function

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  ngOnInit() {
    if (!isDevMode()) {
      console.log = function () { }
    }
  }

}
