<app-step></app-step>

<div class="k-container">
  <div class="principal">
    <h4 class="titulo">{{ lang.title }}</h4>
    <p class="textos primTexto">
      {{ lang.caption }}
    </p>

    <p class="textos">
      {{ lang.text }}
    </p>

    <label class="check checkbox">
      <input
        type="checkbox"
        (change)="mudancaCheck($event)"
        [checked]="setChecked"
      />
      <span class="selector"></span>
      <span>
        {{ lang.labelCheckbox }}
        <button type="button" class="btnTermos" (click)="openModal($event)">
          {{ lang.link }}
        </button>
      </span>
    </label>

    <app-input-error
      *ngIf="erroPrivacidade"
      [textError]="textMessageError"
    ></app-input-error>

    <div class="navigate-button">
      <!-- <button class="botaoVoltar" (click)="paginaAnterior()">
      <i class="fas fa-arrow-alt-circle-left"></i>
      <span> Voltar </span>
    </button> -->
      <button
        class="botaoContinuar"
        [class.btnInativo]="!botaoAtivo"
        (click)="proximaPagina()"
      >
        <span> {{ langButton.continue }} </span>
        <i class="fas fa-arrow-alt-circle-right"></i>
      </button>
    </div>
  </div>
</div>

<app-modal
  [id]="idModal"
  class="textoCondicoes"
  size="modal-xl"
  [btnFechar]="langButton.close"
>
  <div [innerHTML]="textoPrivacidade | sanitizeHtml"></div>
</app-modal>

<app-help-button></app-help-button>
