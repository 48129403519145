<app-step></app-step>

<div class="k-container">
  <div class="principal">
    <div>
      <h4 class="titulo">{{ lang.title }}</h4>
    </div>

    <p class="textos">
      {{ lang.text }}
    </p>

    <p class="textos textos__ultimo">{{ lang.caption }}</p>

    <form [formGroup]="formAluno" (ngSubmit)="proximaPagina()">
      <div class="ipt">
        <label class="ipt__label"> {{ lang.labelFullName }} </label>
        <input
          class="ipt__campo"
          formControlName="fullName"
          type="text"
          maxlength="40"
          [placeholder]="lang.placeholderFullName"
          (paste)="$event.preventDefault()"
          (keypress)="alphaOnly($event)" />
        <div
          *ngIf="
              formAluno.get('fullName')?.invalid &&
              (formAluno.get('fullName')?.dirty ||
                formAluno.get('fullName')?.touched)
            ">
          <app-input-error
            *ngIf="formAluno.get('fullName')?.errors?.['required']"
            [textError]="langInputError.required"></app-input-error>
          <app-input-error
            *ngIf="formAluno.get('fullName')?.errors?.['pattern']"
            [textError]="langInputError.incompleteFullName"></app-input-error>
        </div>
      </div>

      <div class="ipt">
        <div>
          <label class="ipt__label"> {{ lang.labelDateOfBirth }} </label>
          <input
            class="ipt__campo"
            formControlName="dateOfBirth"
            type="text"
            inputmode="numeric"
            mask="00/00/0000"
            [dropSpecialCharacters]="false"
            [placeholder]="placeholderDate" />
        </div>
        <div
          *ngIf="
              formAluno.get('dateOfBirth')?.invalid &&
              (formAluno.get('dateOfBirth')?.dirty ||
                formAluno.get('dateOfBirth')?.touched)
            ">
          <app-input-error
            *ngIf="formAluno.get('dateOfBirth')?.errors?.['required']"
            [textError]="langInputError.required"></app-input-error>
          <app-input-error
            *ngIf="formAluno.get('dateOfBirth')?.errors?.futureDate"
            [textError]="langInputError.futureDate"></app-input-error>
          <app-input-error
            *ngIf="formAluno.get('dateOfBirth')?.errors?.pastDate"
            [textError]="langInputError.pastDate"></app-input-error>
          <app-input-error
            *ngIf="formAluno.get('dateOfBirth')?.errors?.invalidDate"
            [textError]="langInputError.invalidDate"></app-input-error>
          <app-input-error
            *ngIf="formAluno.get('dateOfBirth')?.errors?.incompleteDate"
            [textError]="langInputError.incompleteDate"></app-input-error>
          <app-input-error
            *ngIf="formAluno.get('dateOfBirth')?.errors?.minDate"
            [textError]="langInputError.minDate"></app-input-error>
        </div>
      </div>

      <div class="ipt">
        <label class="ipt__label"> {{ lang.labelCellphone }} </label>

        <div class="container-code-number">
          <ng-select
            #select
            appendTo=".ipt"
            class="code"
            [items]="allCountries"
            bindLabel="name"
            bindValue="dialCode"
            [clearable]="false"
            [searchable]="false"
            formControlName="code"
            (change)="onSelectCountryCode($event.isoCode)">
            <ng-template ng-label-tmp let-item="item">
              <img
                *ngIf="item.flag"
                class="label-img"
                [src]="item.flag"
                [alt]="item.name" />
              <span *ngIf="!item.flag" class="label-name">{{
                item.name
                }}</span>
              <span class="label-code">{{ item.dialCode }}</span>
              <!-- <span *ngIf="!item.flag" class="option-name">{{
                  item.name
                }}</span> -->
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <img
                *ngIf="item.flag"
                class="option-img"
                [src]="item.flag"
                [alt]="item.name" />
              <span class="option-name">{{ item.name }}</span>
              <span class="option-code">{{ item.dialCode }}</span>
            </ng-template>
          </ng-select>

          <input
            class="ipt__campo"
            formControlName="cellphone"
            type="text"
            [mask]="cellphoneFormat"
            [placeholder]="cellphonePlaceholder"
            (keypress)="numbersOnly($event)"
            inputmode="numeric" />
        </div>

        <div
          *ngIf="
              formAluno.get('cellphone')?.invalid &&
              (formAluno.get('cellphone')?.dirty ||
                formAluno.get('cellphone')?.touched)
            ">
          <app-input-error
            *ngIf="formAluno.get('cellphone')?.errors?.['required']"
            [textError]="langInputError.required"></app-input-error>
          <app-input-error
            *ngIf="formAluno.get('cellphone')?.errors?.minlength"
            [textError]="langInputError.incompletePhone"></app-input-error>
        </div>
      </div>

      <div class="navigate-button">
        <!-- <button class="botaoVoltar" (click)="paginaAnterior()">
          <i class="fas fa-arrow-alt-circle-left"></i>
          <span> Voltar </span>
        </button> -->
        <button
          class="botaoContinuar"
          [disabled]="!formAluno.valid"
          [class.btnCarregando]="botaoCarregando"
          type="submit">
          <span> {{ langButton.continue }} </span>
          <i [class]="iconeBotaoEmail"></i>
        </button>
      </div>
    </form>

    <!-- <app-message
      *ngSwitchCase="false"
      icon="fas fa-exclamation"
      colorIcon="error"
      [titulo]="tituloDadosErrados"
      class="containerMessageError"
    >
      <p>{{ txtDadosErrados }}</p>
      <button type="button" class="btnMain" (click)="fecharErro()">
        {{ langButton.tryAgain }}
      </button>
    </app-message> -->

  </div>
</div>
<app-help-button></app-help-button>