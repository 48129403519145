import { Injectable } from '@angular/core';

const KEY = 'answers' 

@Injectable({
  providedIn: 'root'
})
export class AnswersService {

  constructor() { }

  hasAnswers(){
    return !!this.getAnswers();
  }

  setAnswers(obj: any){

    if (!this.hasAnswers()) {
      sessionStorage.setItem(KEY, JSON.stringify(obj))
    } else {
      sessionStorage.setItem(KEY, JSON.stringify(Object.assign(this.getAnswers(), obj)))
    }

  }

  getAnswers(){
    const data = sessionStorage.getItem(KEY)
    return JSON.parse(data!)
  }

  removeAnswers(){
    sessionStorage.removeItem(KEY)
  }
}
