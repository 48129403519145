<app-loading *ngIf="isLoading; else conteudo"></app-loading>

<ng-template #conteudo>
  <app-step></app-step>

  <div class="k-container">
    <div class="principal" [ngSwitch]="isDadosConfirmados">

      <div *ngSwitchCase="true">
        <div>
          <h4 class="titulo">{{ lang.title }}</h4>
        </div>

        <p class="label">{{ lang.label }}</p>

        <div class="box-buttons">

          <button type="button" class="btnMethod" (click)="newConsent('sms')">
            <i class="fas fa-comment"></i>
            <div>
              <p class="title">{{lang.textSMS}}</p>
              <p class="value"> {{ cellphone }} </p>
            </div>
          </button>

          <button type="button" class="btnMethod" (click)="newConsent('email')">
            <i class="fas fa-envelope"></i>
            <div>
              <p class="title">{{lang.textEmail}}</p>
              <p class="value"> {{ email }} </p>
            </div>
          </button>
        </div>

        <div class="navigate-button">
          <button class="botaoVoltar" (click)="paginaAnterior()">
            <i class="fas fa-arrow-alt-circle-left"></i>
            <span> {{ langButtons.back }} </span>
          </button>
        </div>

        <app-help-button></app-help-button>
      </div>

      <app-message
        *ngSwitchCase="false"
        icon="fas fa-exclamation"
        colorIcon="error"
        [titulo]="tituloDadosErrados"
        class="containerMessageError">
        <p>{{ txtDadosErrados }}</p>
        <button type="button" class="btnMain" (click)="fecharErro()">
          {{ langButtons.tryAgain }}
        </button>
      </app-message>

    </div>
  </div>
</ng-template>