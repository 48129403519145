import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() id: any
  @Input() titulo: string = ''
  @Input() size: string = 'modal-lg'
  // @Input() corpo: string = ''
  @Input() btnFechar: string = 'Fechar'
  @Input() btnSalvar: string = 'Salvar'
  @Input() hasBtnSalvar: boolean = false
  @Input() isScrollable: boolean = false
  @Input() scrollable: string = ''
  @Input() hasFooter: boolean = true

  constructor() { }

  ngOnInit(): void {
    this.setScrollable()
  }

  setScrollable() {
    return this.isScrollable ? this.scrollable = 'modal-dialog-scrollable' : this.scrollable = ''
  }

}
