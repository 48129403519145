import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from './../../environments/environment'
import { CountryListService } from './country-list.service'

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  hash: string = ''
  validHashCode: boolean = true

  private readonly url_Consent = `${environment.API}/Consent`
  private readonly url_Docs = `${environment.API}/Docs`

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private countryList: CountryListService
  ) {
    this.getHashCode()
  }

  isUserLoggedIn() {
    return !!sessionStorage.getItem('UserLogged')
  }

  getHashCode() {
    this.route.queryParams.subscribe(
      param => {

        if (param.hash === undefined || param.hash === '') {
          this.validHashCode = false
        } else {
          this.hash = param.hash
        }

        return this.hash
      }
    )
  }

  current(): Observable<any> {
    return this.http.get(`${this.url_Consent}/current/${this.hash}`)
  }

  getKsisData(country: string): Observable<any> {
    return this.http.get(`${this.url_Consent}/ksisdata/${country}/${this.hash}`)
  }

  email(user: any): Observable<any> {
    return this.http.post(`${this.url_Consent}/email`, user).pipe(
      map(data => {
        if (data) {
          sessionStorage.setItem('UserLogged', JSON.stringify(data))
        }
        return data
      })
    )
  }

  newConsent(user: any): Observable<any> {
    return this.http.post(`${this.url_Consent}/new`, user)
  }

  smsValidade(body: any): Observable<any> {
    return this.http.post(`${this.url_Consent}/new/sms/validate`, body)
  }

  smsResend(hash: string): Observable<any> {
    return this.http.post(`${this.url_Consent}/new/sms/resend/${hash}`, {})
  }

  questions(hash: string, country: string): Observable<any> {
    return this.http.get(`${this.url_Consent}/questions/${country}/${hash}`)
  }

  answers(obj: any): Observable<any> {
    return this.http.post(`${this.url_Consent}/answers`, obj)
  }

  finish(hash: string, country: string = 'BR'): Observable<any> {
    return this.http.get(`${this.url_Consent}/new/finish/${hash}`, {
      params: {
        countryId: country
      }
    })
  }


  privacidade(country: string): Observable<any> {
    return this.http.get(`${this.url_Docs}/privacidade`, {
      params: {
        countryId: country
      }
    })
  }

  menores(country: string): Observable<any> {
    return this.http.get(`${this.url_Docs}/menores`, {
      params: {
        countryId: country
      }
    })
  }

  imagemAdultos(country: string): Observable<any> {
    return this.http.get(`${this.url_Docs}/imagemAdultos`, {
      params: {
        countryId: country
      }
    })
  }


  splitName(value: string) {
    let fullName = value.split(' ')
    let regex = /(filho|fo|f)$|(junior|júnior|jr)$|(neto|nt)$|(sobrinho|sob)$/i

    let dados = {
      firstName: fullName[0],
      middleName: '',
      lastName: ''
    }

    if (regex.test(value)) {
      dados.middleName = fullName.slice(1, -2).join(' ')
      dados.lastName = fullName.slice(-2).join(' ')
    } else {
      dados.middleName = fullName.slice(1, -1).join(' ')
      dados.lastName = fullName.slice(-1).join(' ')
    }

    return dados
  }


  removeSpaces(text: string, removeDot = false) {

    if (!text) return ''

    let result

    if (removeDot) {
      result = text.replace(/\.+/g, " ")
      result = result.trim().replace(/\s+/g, " ")
    } else {
      result = text.trim().replace(/\s+/g, " ")
    }

    return result
  }

}
