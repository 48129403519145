import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Buttons, ErrorInput, Verificacao } from 'src/app/models/language.model'
import { ApisService } from 'src/app/services/apis.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-verificacao',
  templateUrl: './confirmar-codigo.component.html',
  styleUrls: ['./confirmar-codigo.component.scss']
})
export class ConfirmarCodigoComponent implements OnInit {

  lang: Verificacao = this.language.verificacao
  langButtons: Buttons = this.language.buttons
  langInputError: ErrorInput = this.language.errorInput

  sendMethod!: string

  textResendError = ''

  country = this.dados.getDados().countryId
  cellphoneFormat = ''
  botaoAtivo = false
  timer = 30
  botaoCarregando = false
  iconeBotao = "fas fa-arrow-alt-circle-right"
  smsValido = true
  isReenviar = false
  resendError = false
  cellphone = ''
  email = ''
  isBackButtonDisabled: boolean = true

  smsInput = new FormControl('', [Validators.required])

  hash = this.dados.getDados().hash

  constructor(
    private router: Router,
    private apiService: ApisService,
    private dados: DadosService,
    private language: LanguageService
  ) { }

  ngOnInit(): void {
    this.contador()
    this.recuperaDados()

    this.sendMethod = this.dados.getDados().sendCodeMethod
  }

  recuperaDados() {

    try {
      // const codeLength = this.dados.getDados().code.length

      this.cellphone = this.dados.getDados().cellphone
      this.email = this.dados.getDados().studentEmailAddress

    } catch (e) {
      console.log('ERRO Recupera dados!!!', e)
    }
  }

  contador() {
    setInterval(() => {
      if (this.timer > 0) {
        this.timer--
      } else {
        this.isReenviar = true
        this.isBackButtonDisabled = false
      }
    }, 1000)
  }

  proximaPagina() {
    this.iconeBotao = "fas fa-spinner"
    this.botaoCarregando = true

    let body = {
      phonecode: this.smsInput.value,
      hash: this.hash
    }

    this.apiService.smsValidade(body).subscribe(
      res => {

        if (res) {
          this.router.navigate(['/termos'])
        } else {
          this.smsValido = false
          this.botaoCarregando = false
          this.iconeBotao = "fas fa-arrow-alt-circle-right"
        }

      },
      err => {
        this.smsValido = false
        this.botaoCarregando = false
        this.iconeBotao = "fas fa-arrow-alt-circle-right"
      }
    )

  }

  reenviar() {
    this.apiService.smsResend(this.hash).subscribe(
      res => {

      },
      err => {

        if (err.status === 200) {

          this.isReenviar = false
          this.timer = 30
          this.textResendError = this.langInputError.resentSMS

        } else {
          this.textResendError = this.language.apiError(err.error)
        }

        this.resendError = true

        setTimeout(() => {
          this.resendError = false
        }, 4000)
      }
    )
  }

  limpaMensagemErro() {
    this.smsValido = true
  }


  paginaAnterior() {
    this.router.navigate(['/enviar-codigo'])
  }


}
