import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() icon: string = ''
  @Input() titulo: string = ''
  @Input() colorIcon: string = ''

  constructor() { }


  ngOnInit(): void {

    switch (this.colorIcon) {
      case 'success':
        this.colorIcon = 'success'
        break;
      case 'info':
        this.colorIcon = 'info'
        break;
      case 'error':
        this.colorIcon = 'error'
        break;

      default:
        break;
    }
  }

}
