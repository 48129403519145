<!-- Modal -->
<div
  class="modal fade"
  id="{{ id }}"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog {{ size }} {{ scrollable }}">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ titulo }}</h5>
        <ng-content select="[header]"></ng-content>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <ng-content class="modal-body"></ng-content>
      </div>
      <div class="modal-footer" *ngIf="hasFooter">
        <button type="button" class="btn btn-fechar" data-bs-dismiss="modal">
          {{ btnFechar }}
        </button>
        <button type="button" class="btn btn-primary" *ngIf="hasBtnSalvar">
          {{ btnSalvar }}
        </button>
      </div>
    </div>
  </div>
</div>
