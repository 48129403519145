import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import * as bootstrap from 'bootstrap'
import { Modal } from 'bootstrap'
import { take } from 'rxjs/operators'
import { ConsentAnswer } from 'src/app/models/answers.model'
import { Buttons, ErrorMessage, Termos } from 'src/app/models/language.model'
import { AnswersService } from 'src/app/services/answersStorage.service'
import { ApisService } from 'src/app/services/apis.service'
import { LanguageService } from 'src/app/services/language.service'
import { DadosService } from './../../services/dados.service'

@Component({
  selector: 'app-termos',
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermosComponent implements OnInit {

  lang: Termos = this.language.termos
  langButtons: Buttons = this.language.buttons
  langMessage: ErrorMessage = this.language.errorMessage

  isLoading: boolean = true
  isQuestionsLoaded: boolean = true
  txtDadosErrados: string = ''
  botaoAtivo = true
  isRequiredYes!: boolean
  idModal: string = 'modalTermo'
  modal: Modal | undefined

  texto = ''
  textoImagem = ''
  textoMenor = ''

  questions: any = []
  newConsent: ConsentAnswer = {}

  constructor(
    private api: ApisService,
    private router: Router,
    private answersStorage: AnswersService,
    private dados: DadosService,
    private language: LanguageService
  ) { }

  ngOnInit(): void {
    this.getQuestions()

    this.getMenores()
    this.getImagemAdultos()
  }

  getQuestions() {
    this.api.questions(this.dados.getDados().hash, this.dados.getDados().countryId)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {

          if (res.length === 0) {
            this.dados.setDados({ hasConditions: false })
            this.router.navigate(['dados-responsavel'])

          } else {

            this.dados.setDados({ hasConditions: true })
            this.questions = res
            this.isLoading = false

          }


        },
        err => {
          this.isLoading = false
          this.isQuestionsLoaded = false
          this.txtDadosErrados = this.language.apiError(err.error)
        }
      )
  }

  getMenores() {
    this.api.menores(this.dados.getDados().countryId).subscribe(
      res => {
        this.textoMenor = res.contentData
      },
      err => {
      }
    )
  }

  getImagemAdultos() {
    this.api.imagemAdultos(this.dados.getDados().countryId).subscribe(
      res => {
        //console.log(res.contentData)
        this.textoImagem = res.contentData
      },
      err => {
        console.log(err)
      }
    )
  }

  check(ev: Event) {
    let statusCheck = ev.target as HTMLInputElement
  }


  proximaPagina(form: any) {

    let dados: any

    dados = Object.values(form.value)

    this.newConsent = this.questions.map((item: any, index: any) => {

      return {
        "question": item,
        "consentAnswerId": 0,
        "consentId": this.answersStorage.getAnswers().consentId,
        "questionId": item.questionId,
        "consentAnswer1": dados[index],
        "consent": "",
      }

    })

    this.answersStorage.setAnswers({ consentAnswer: this.newConsent })

    this.router.navigate(['dados-responsavel'])
  }

  paginaAnterior() {
    this.router.navigate(['enviar-codigo'])
  }

  fecharErro() {
    this.isLoading = true
    this.isQuestionsLoaded = true
    this.getQuestions()
  }

  openModalMenores(ev: Event) {

    this.modal = new bootstrap.Modal(document.getElementById(this.idModal)!)
    this.modal.show()

    this.texto = this.textoMenor
  }

  openModalImagem(ev: Event) {

    this.modal = new bootstrap.Modal(document.getElementById(this.idModal)!)
    this.modal.show()

    this.texto = this.textoImagem
  }

}