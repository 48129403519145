import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Answers } from 'src/app/models/answers.model'
import { Buttons, Codigo } from 'src/app/models/language.model'
import { NewConsent, SendCodeMethod } from 'src/app/models/newConsent.model'
import { AnswersService } from 'src/app/services/answersStorage.service'
import { ApisService } from 'src/app/services/apis.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-codigo',
  templateUrl: './enviar-codigo.component.html',
  styleUrls: ['./enviar-codigo.component.scss']
})
export class EnviarCodigoComponent implements OnInit {

  lang: Codigo = this.language.codigo
  langButtons: Buttons = this.language.buttons

  isLoading = false
  email: string = ''
  cellphone: any
  isDadosConfirmados: boolean = true
  tituloDadosErrados = this.language.errorMessage.titleMessageError
  txtDadosErrados: string = ''

  constructor(
    private router: Router,
    private apiService: ApisService,
    private dados: DadosService,
    private answers: AnswersService,
    private language: LanguageService
  ) { }

  ngOnInit(): void {
    this.recuperaDados()

    console.log(this.answers.getAnswers())
  }

  recuperaDados() {

    try {
      // const codeLength = this.dados.getDados().code.length

      this.cellphone = this.dados.getDados().cellphone
      this.email = this.dados.getDados().studentEmailAddress

    } catch (e) {
      console.log('ERRO!!!', e)
    }
  }

  addInfoToAnswers(webStudentId: string, consentId: number) {

    let content: Answers = {
      ...this.answers.getAnswers(),
      webStudentId: webStudentId,
      consentId: consentId,
      webStudent: {
        ...this.answers.getAnswers().webStudent,
        webStudentId: webStudentId,
        guardian: [
          {
            ...this.answers.getAnswers().webStudent.guardian[0],
            webStudentId: webStudentId
          }
        ]
      },
      systemInformation: [
        {
          ...this.answers.getAnswers().systemInformation[0],
          consentId: consentId
        }
      ]
    }

    this.answers.setAnswers(content)

  }

  newConsent(sendCodeMethod: SendCodeMethod) {

    this.isLoading = true

    let content: NewConsent = {
      fullName: this.dados.getDados().fullName,
      dateOfBirth: this.dados.getDados().dateOfBirth,
      cellphone: this.dados.getDados().code + this.dados.getDados().cellphone,
      hash: this.dados.getDados().hash,
      centerId: this.dados.getDados().centerId,
      countryId: this.dados.getDados().countryId,
      email: this.dados.getDados().studentEmailAddress,
      sendCodeMethod: sendCodeMethod
    }

    console.log(content)

    this.apiService.newConsent(content).subscribe(
      {
        next: res => {
          console.log('sendCodeMethod', sendCodeMethod)

          this.addInfoToAnswers(
            res.webStudentId,
            res.consentId
          )

          this.dados.setDados({ sendCodeMethod })

          console.log(this.answers.getAnswers())

          this.router.navigate(['/confirmar-codigo'], { replaceUrl: true })
        },
        error: err => {
          this.isLoading = false
          this.isDadosConfirmados = false
          this.txtDadosErrados = this.language.apiError(err.error)
        }
      }
    )
  }

  paginaAnterior() {
    this.router.navigate(['/cadastro-inicial'])
  }

  fecharErro() {
    this.isDadosConfirmados = true
  }
}
