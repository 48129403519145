import { AuthGuard } from './guards/auth.guard'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { HomeComponent } from './pages/home/home.component'
import { CadastroInicialComponent } from './pages/cadastro-inicial/cadastro-inicial.component'
import { EnviarCodigoComponent } from './pages/enviar-codigo/enviar-codigo.component'
import { TermosComponent } from './pages/termos/termos.component'
import { DadosResponsavelComponent } from './pages/dados-responsavel/dados-responsavel.component'
import { PrivacidadeComponent } from './pages/privacidade/privacidade.component'
import { ConfirmarCodigoComponent } from './pages/confirmar-codigo/confirmar-codigo.component'
import { CheckComponent } from './pages/check/check.component'

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'privacidade', component: PrivacidadeComponent, canActivate: [AuthGuard] },
  { path: 'cadastro-inicial', component: CadastroInicialComponent, canActivate: [AuthGuard] },
  { path: 'enviar-codigo', component: EnviarCodigoComponent, canActivate: [AuthGuard] },
  { path: 'confirmar-codigo', component: ConfirmarCodigoComponent, canActivate: [AuthGuard] },
  { path: 'termos', component: TermosComponent, canActivate: [AuthGuard] },
  { path: 'dados-responsavel', component: DadosResponsavelComponent, canActivate: [AuthGuard] },
  { path: 'check', component: CheckComponent },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
