import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service'

@Injectable()
export class ConsentInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const headers = request.clone({
      headers: request.headers
        .set('Authorization', `Bearer ${this.tokenService.getToken()}`)
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Credentials', 'true')
      // .set('Access-Control-Allow-Origin', '*')
    })

    // console.log('ConsentInterceptor is working')

    return next.handle(headers)
  }
}
