import { AbstractControl } from '@angular/forms'
import * as moment from 'moment'

export function futureDate(control: AbstractControl) {

    if (control.value.length === 10) {
        let today = moment()
        let inputDate = moment(control.value, 'DD/MM/YYYY')

        if (inputDate.isAfter(today)) {
            return { futureDate: true }
        }
    }

    return null

}

export function pastDate(control: AbstractControl) {

    if (control.value.length === 10) {
        let cemAnos = moment().subtract(100, 'years')
        let inputDate = moment(control.value, 'DD/MM/YYYY')

        if (inputDate.isBefore(cemAnos)) {
            return { pastDate: true }
        }
    }

    return null
}

export function minDate(control: AbstractControl) {

    if (control.value.length === 10) {
        let min = moment().subtract(1, 'days')
        let max = moment()
        let inputDate = moment(control.value, 'DD/MM/YYYY')

        if (inputDate.isBetween(min, max)) {
            return { minDate: true }
        }
    }


    return null
}

export function invalidDate(control: AbstractControl) {

    let inputDate = moment(control.value, 'DD/MM/YYYY')

    if (control.value.length === 10) {
        if (!inputDate.isValid()) {
            return { invalidDate: true }
        }
    } else if (control.value.length > 0 && control.value.length < 10) {
        return { incompleteDate: true }
    }


    return null
}

