import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { concatMap, map, switchMap, tap } from 'rxjs/operators'
import { ApisService } from 'src/app/services/apis.service'
import { AuthService } from 'src/app/services/auth.service'
import { DadosService } from 'src/app/services/dados.service'
import { TokenService } from 'src/app/services/token.service'
import { environment } from 'src/environments/environment'
import { Buttons, Home } from '../../models/language.model'
import { LanguageService } from './../../services/language.service'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  getData$!: Subscription

  lang!: Home
  langButton!: Buttons

  textLoading = this.language.home.textLoadingValidate
  titleMessage = ''
  textMessage = ''

  isLoading: boolean = true
  isAuthenticated: boolean = true
  displayEmail: string = ''
  emailValido = true
  msgError: string = ''
  colorIcon = 'error'
  icon = 'fas fa-exclamation'
  mostraBotaoTentarNovamente = false
  botaoAtivo = false
  botaoCarregando = false
  iconeBotaoEmail = 'fas fa-arrow-alt-circle-right'

  user = {
    hash: '',
    email: '',
  };

  studentEmailAddress = {}

  emailUsuario = new FormControl('', [Validators.required, Validators.email])


  private readonly linkConsent = `${environment.urlConsent}`
  private readonly linkConsentLA = `${environment.urlConsentLA}`
  private readonly linkEnrollment = `${environment.urlEnrollment}`
  private readonly linkEnrollmentLA = `${environment.urlEnrollmentLA}`

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private apiService: ApisService,
    private router: Router,
    private dados: DadosService,
    private language: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData() {

    this.getData$ = this.authService.auth()
      .pipe(

        map((data: any) => {
          // console.log('⚪ Token', data.token)
          this.tokenService.setToken(data.token)
          return data.token
        }),

        switchMap(() => this.getCurrent()),

        // tap(res => console.log('🟡 getCurrent', res)),

        concatMap((res) => this.getKsisData(res.countryId)),

        // tap(res => console.log('🟡 getKsisData', res))
      )
      .subscribe({
        next: res => {

          console.log('🟢 Subscribe', res)
          this.isLoading = false

        },
        error: err => {

          console.log('🔴 Error', err)
          this.handleCurrentError(err)
        }
      })
  }

  getCurrent() {
    return this.apiService.current().pipe(
      map(res => {
        this.textLoading = this.language.home.textLoadingUpdate

        if (res.email) {
          this.displayEmail = res.email
          this.user.hash = this.apiService.hash
          this.dados.setDados({
            ...res,
            hash: this.apiService.hash
          })
        }

        if (res.countryId) {
          this.lang = this.language.home
          this.langButton = this.language.buttons
        }

        return res
      })
    )
  }

  getKsisData(country: string) {
    return this.apiService.getKsisData(country).pipe(
      map(res => {

        if (res) {
          this.dados.setDados({
            cellphone: (res?.phoneCode + res?.phoneNumber) || '',
            dateOfBirth: res?.dateOfBirth || '',
            fullName: res?.studentName || '',
            ksis: true
          })
        }
        return res
      })
    )
  }

  handleCurrentError(err: any) {

    let hash = this.apiService.hash

    if (err.status === 308) {

      let origin = this.document.location.origin

      console.log('origin', origin)

      switch (origin) {
        case this.linkConsent:
          return this.document.location.replace(`${this.linkEnrollment}/?hash=${hash}`)

        case this.linkConsentLA:
          return this.document.location.replace(`${this.linkEnrollmentLA}/?hash=${hash}`)

        default:
          return this.document.location.replace(`http://localhost:5200/?hash=${hash}`)
      }

    }
    else if (err.status === 409) {
      this.colorIcon = 'success'
      this.icon = 'fas fa-check'
      this.titleMessage = this.language.errorMessage.titleMessageEnrollmentComplete
      this.textMessage = this.language.errorMessage.textMessageEnrollmentComplete

      this.isAuthenticated = false
      this.isLoading = false
    }
    else if (err.status === 404) {
      this.titleMessage = this.language.errorMessage.titleMessageError
      this.textMessage = this.language.errorMessage.textMessageError

      this.isAuthenticated = false
      this.isLoading = false
    }
    else {
      this.textMessage = this.language.apiError(err.error)

      this.isAuthenticated = false
      this.isLoading = false
    }
  }

  confirmEmail() {
    this.user.email = this.emailUsuario.value

    this.apiService.email(this.user).subscribe(
      res => {
        this.router.navigate(['privacidade'])

        this.studentEmailAddress = {
          studentEmailAddress: this.emailUsuario.value
        }

        this.dados.setDados(this.studentEmailAddress)

      },
      err => {
        this.botaoCarregando = false
        this.iconeBotaoEmail = 'fas fa-arrow-alt-circle-right'
        this.msgError = this.language.apiError(err.error)
        this.emailValido = false

        console.log(err)
      }
    )
  }

  proximaPagina() {
    this.iconeBotaoEmail = 'fas fa-spinner'
    this.botaoCarregando = true

    this.confirmEmail()
  }

  limpaErro() {
    this.emailValido = true
  }


  fecharErro() {
    this.getData()
  }


  ngOnDestroy(): void {
    this.getData$.unsubscribe()
  }
}
