<app-loading *ngIf="isLoading; else conteudo"></app-loading>

<ng-template #conteudo [ngSwitch]="isQuestionsLoaded">
  <app-step></app-step>
  <div class="k-container">
    <div class="principal">
      <div *ngSwitchCase="true">
        <div>
          <h4 class="titulo" id="titulo">{{ lang.title }}</h4>
        </div>

        <form #f="ngForm" (ngSubmit)="proximaPagina(f)">
          <div
            class="div-termo"
            *ngFor="let question of questions; let i = index">
            <h1 class="termo-titulo">
              <!-- <span class="termo-number">{{ i + 1 }}</span> -->
              <span class="termo-title">{{ question.title }}</span>
            </h1>

            <div class="inner">
              <div *ngIf="!question.isRequiredYes; else termo">
                <h6 class="termo-subtitulo">{{ question.subheading }}.</h6>
                <app-question-description
                  class="termo-description"
                  (modal)="openModalImagem($event)"
                  [description]="question.questionDescription">
                </app-question-description>

                <div class="multi-select">
                  <div class="termo-select">
                    <label class="check checkbox">
                      <input
                        type="radio"
                        [value]="true"
                        [name]="'termo-' + question.questionId"
                        (change)="check($event)"
                        required
                        ngModel />
                      <span class="selector"></span>
                      <span class="termo-description"> Estou de acordo</span>
                    </label>
                  </div>

                  <div class="termo-select">
                    <label class="check checkbox">
                      <input
                        type="radio"
                        [value]="false"
                        [name]="'termo-' + question.questionId"
                        (change)="check($event)"
                        required
                        ngModel />
                      <span class="selector"></span>
                      <span class="termo-description">
                        Não estou de acordo</span>
                    </label>
                  </div>
                </div>
              </div>

              <ng-template #termo>
                <h6 class="termo-subtitulo">{{ question.subheading }}.</h6>
                <div class="termo-select">
                  <label class="check checkbox">
                    <input
                      type="checkbox"
                      [value]="true"
                      id="question.questionId"
                      (change)="check($event)"
                      [name]="'termo-' + question.questionId"
                      required
                      ngModel />
                    <span class="selector"></span>
                  </label>
                  <app-question-description
                    class="termo-description"
                    (modal)="openModalMenores($event)"
                    [description]="question.questionDescription">
                  </app-question-description>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="navigate-button">
            <button class="botaoContinuar" type="submit" [disabled]="!f.valid">
              <span> {{ langButtons.continue }} </span>
              <i class="fas fa-arrow-alt-circle-right"></i>
            </button>
          </div>
        </form>
      </div>

      <app-message
        *ngSwitchCase="false"
        icon="fas fa-exclamation"
        colorIcon="error"
        [titulo]="langMessage.titleMessageError"
        class="containerMessageError">
        <p>{{ txtDadosErrados }}</p>
        <button type="button" class="btnMain" (click)="fecharErro()">
          {{ langButtons.tryAgain }}
        </button>
      </app-message>
    </div>
  </div>

  <app-help-button *ngSwitchCase="true"></app-help-button>
</ng-template>

<app-modal [id]="idModal" class="textoCondicoes" size="modal-xl">
  <div [innerHTML]="texto | sanitizeHtml"></div>
</app-modal>