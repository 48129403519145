import { Injectable } from '@angular/core'
import {
  Buttons,
  CadastroInicial,
  Codigo,
  DadosResponsavel,
  ErrorInput,
  ErrorMessage,
  Home,
  Prefix,
  Privacidade,
  Steps,
  Termos,
  Verificacao,
} from './../models/language.model'
import { DadosService } from './dados.service'

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  currentCountry = ''
  urlCountry = window.location.hostname.split('.').pop()!.substring(0, 2).toUpperCase()

  constructor(private dadosService: DadosService) {
    console.log('getcountry', this.country)
  }

  get country() {
    return this.dadosService.hasDados()
      ? (this.currentCountry = this.dadosService.getDados().countryId.toUpperCase())
      : (this.currentCountry = this.urlCountry)
  }

  selectLanguage(arr: any[]) {
    let countrySelected
    const spanish = 'HH'

    const countryFilter = arr.find(item => item.country === this.country)
    const noCountryFound = arr.find(item => item.country === spanish)

    return countryFilter ? (countrySelected = countryFilter) : (countrySelected = noCountryFound)
  }

  get steps() {
    const content: Steps[] = [
      {
        country: 'BR',
        one: 'Política de privacidade',
        two: 'Dados cadastrais',
        three: 'Escolha da Disciplina',
        four: 'Condições do curso',
        five: 'Finalização',
      },
      {
        country: 'HH',
        one: 'Política de Privacidad',
        two: 'Datos de Registro',
        three: 'Selección de asignatura',
        four: 'Condiciones del curso',
        five: 'Finalización',
      },
    ]

    return this.selectLanguage(content)
  }

  get home() {
    const content: Home[] = [
      {
        country: 'BR',
        email: 'Você está iniciando o processo de matrícula para o e-mail:',
        confirmEmail: 'Confirme o e-mail no campo abaixo:',
        placeholder: 'Insira seu e-mail',
        textLoadingValidate: 'Validando os dados',
        textLoadingUpdate: 'Atualizando informações',
      },
      {
        country: 'HH',
        email: 'Usted está iniciando el proceso de matrícula para el e-mail:',
        confirmEmail: 'Confirme su e-mail en el campo abajo:',
        placeholder: 'Ingresa tu e-mail',
        textLoadingValidate: 'Validando los datos',
        textLoadingUpdate: 'Actualizando informaciones',
      },
    ]

    return this.selectLanguage(content)
  }

  get privacidade() {
    const content: Privacidade[] = [
      {
        country: 'BR',
        title: 'Um ponto importante!',
        caption: 'O Kumon se preocupa com a privacidade de seus alunos.',
        text: 'Leia nossa política de privacidade para entender como tratamos os seus dados pessoais e siga com o processo.',
        labelCheckbox: 'Li e estou ciente da',
        link: 'política de privacidade.',
        textMessageError:
          'Houve um problema ao carregar a Política de Privacidade, tente novamente mais tarde.',
      },
      {
        country: 'HH',
        title: '¡Un punto importante!',
        caption: 'Kumon se preocupa con la privacidad de sus alumnos.',
        text: 'Lea nuestra política de privacidad para comprender como manejamos sus datos personales y siga el proceso.',
        labelCheckbox: 'He leído y estoy de acuerdo con la',
        link: 'política de privacidad.',
        textMessageError:
          'Hubo un problema al cargar la Política de privacidad, intente nuevamente más tarde.',
      },
    ]

    return this.selectLanguage(content)
  }

  get cadastroInicial() {
    let content: CadastroInicial[] = [
      {
        country: 'BR',
        title: 'Política de Privacidade',
        text: 'Agora que você já sabe como o Kumon trata seus dados pessoais, precisamos confirmar algumas informações importantes para sua segurança.',
        caption: 'Preencha abaixo:',
        labelFullName: 'Nome completo do aluno',
        placeholderFullName: 'Digite o nome',
        labelDateOfBirth: 'Data de nascimento do aluno',
        labelCellphone: 'Celular do responsável',
        selectOtherCountry: 'Outro',
        placehoderKSACountry: 'Cód. localidade + número',
        placehoderOtherCountry: 'Cód. país + localidade + número',
      },
      {
        country: 'HH',
        title: 'Política de Privacidad',
        text: 'Ahora que sabe como Kumon maneja sus datos personales, necesitamos confirmar algunas informaciones importantes para su seguridad.',
        caption: 'Complete abajo:',
        labelFullName: 'Nombre completo del alumno',
        placeholderFullName: 'Ingrese el nombre',
        labelDateOfBirth: 'Fecha de nacimiento del alumno',
        labelCellphone: 'Celular de la persona responsable',
        selectOtherCountry: 'Otro',
        placehoderKSACountry: 'Cód. localidad + número',
        placehoderOtherCountry: 'Cód. país + localidad + número',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'BO',
        placehoderKSACountry: 'Número',
      }
    ]

    return this.selectLanguage(content)
  }

  get codigo() {
    const content: Codigo[] = [
      {
        country: 'BR',
        title: 'Política de Privacidade',
        label: 'Escolha como receber o código de verificação',
        textEmail: 'E-mail',
        textSMS: 'SMS para o celular'
      },
      {
        country: 'HH',
        title: 'Política de Privacidad',
        label: 'Elige cómo recibir el código de verificación',
        textEmail: 'E-mail',
        textSMS: 'SMS al celular'
      },
    ]

    return this.selectLanguage(content)
  }

  get verificacao() {
    let content: Verificacao[] = [
      {
        country: 'BR',
        title: 'Política de Privacidade',
        resendSMS: 'Reenviar código',
        countDownSMS: 'Reenviar código em',
        labelEmail: 'Digite o código enviado para o e-mail',
        labelSMS: 'Digite o código SMS enviado para o telefone',
        labelBelow: 'no campo abaixo:'
      },
      {
        country: 'HH',
        title: 'Política de Privacidad',
        resendSMS: 'Reenviar código',
        countDownSMS: 'Reenviar código en',
        labelEmail: 'Ingrese el código enviado al e-mail',
        labelSMS: 'Ingrese el código SMS enviado al teléfono',
        labelBelow: 'en el campo a continuación:'
      }
    ]

    return this.selectLanguage(content)
  }

  get termos() {
    const content: Termos[] = [
      {
        country: 'BR',
        title: 'Política de Privacidade',
      },
      {
        country: 'HH',
        title: 'Política de Privacidad',
      },
    ]

    return this.selectLanguage(content)
  }

  get dadosResponsavel() {
    const content: DadosResponsavel[] = [
      {
        country: 'BR',
        title: 'Política de Privacidade',
        text: 'Agora preencha os dados abaixo para finalizarmos esse passo.',
        labelFullName: 'Nome completo do responsável',
        placeholderFullName: 'Digite o nome',
        labelEmail: 'E-mail do responsável',
        labelConfirmEmail: 'Confirmação do e-mail',
        titleInfo: 'Importante',
        textInfo:
          'Enviaremos para o e-mail abaixo as instruções para ativação de conta de e-mail educacional do aluno.',
      },
      {
        country: 'HH',
        title: 'Política de Privacidad',
        text: 'Ahora completa los datos abajo para concluir este paso.',
        labelFullName: 'Nombre completo de la persona responsable',
        placeholderFullName: 'Ingrese el nombre',
        labelEmail: 'E-mail de la persona responsable',
        labelConfirmEmail: 'Confirmación de e-mail',
        titleInfo: 'Importante',
        textInfo:
          'Enviaremos las instrucciones para activación de la cuenta de e-mail educacional del alumno para el e-mail abajo.',
      },
    ]

    return this.selectLanguage(content)
  }

  get errorMessage() {
    const content: ErrorMessage[] = [
      {
        country: 'BR',
        titleMessageEnrollmentComplete: 'Matrícula já preenchida',
        titleMessageError: 'Encontramos um problema',
        textMessageEnrollmentComplete: `
          <p>
            Parece que você já concluiu o processo de matrícula online.
          </p>
          <p>
            Caso tenha algum problema, entre em contato com o seu orientador para realizar a confirmação.
          </p>
        `,
        textMessageError: `
          <p>
            O link de matrícula que você está utilizando está fora do prazo máximo
            permitido ou está indisponível.
          </p>
          <p>
            Por favor, entre em contato com a unidade e solicite um novo link para
            efetuar a matrícula.
          </p>
        `,
      },
      {
        country: 'HH',
        titleMessageEnrollmentComplete: 'Matrícula ya completada',
        titleMessageError: 'Encontramos un problema',
        textMessageEnrollmentComplete: `
          <p>
            Parece que usted ya completó el proceso de matrícula.
          </p>
          <p>
          Si tiene algún problema, comuníquese con su orientador para confirmación.
          </p>
        `,
        textMessageError: `
          <p>
            El link de matrícula que está utilizando no está actualizado o no está disponible.
          </p>
          <p>
            Comuníquese con la unidad y solicite un nuevo link para registrarse.
          </p>
        `,
      },
    ]

    return this.selectLanguage(content)
  }

  get errorInput() {
    const content: ErrorInput[] = [
      {
        country: 'BR',
        required: 'Campo obrigatório.',
        incompleteFullName: 'Por favor, preencha o nome completo.',
        futureDate: 'O valor informado não pode ser maior do que a data atual.',
        pastDate: 'A data não pode ser anterior à cem anos.',
        minDate: 'A idade mínima é de um ano.',
        invalidDate: 'Data inválida.',
        incompleteDate: 'Data incompleta.',
        incompletePhone: 'Telefone incompleto.',
        invalidSMS: 'Código inválido.',
        resentSMS: 'Código reenviado',
        invalidEmail: 'E-mail inválido.',
        confirmEmail: 'Os e-mails são diferentes.',
      },
      {
        country: 'HH',
        required: 'Información obligatoria.',
        incompleteFullName: 'Por favor, ingrese el nombre completo.',
        futureDate: 'La fecha digitada no puede ser mayor que la fecha actual.',
        pastDate: 'La fecha digitada no puede ser anterior a 100 años.',
        minDate: 'La edad minima es de un año.',
        invalidDate: 'Fecha inválida.',
        incompleteDate: 'Fecha incompleta.',
        incompletePhone: 'Teléfono incompleto.',
        invalidSMS: 'Código inválido.',
        resentSMS: 'El código ha sido reenviado.',
        invalidEmail: 'E-mail inválido.',
        confirmEmail: 'Los e-mails son diferentes.',
      },
    ]

    return this.selectLanguage(content)
  }

  get buttons() {
    const content: Buttons[] = [
      {
        country: 'BR',
        start: 'Iniciar',
        back: 'Voltar',
        continue: 'Continuar',
        tryAgain: 'Tentar novamente',
        close: 'Fechar',
        help: 'Clique aqui para abrir o conteúdo de ajuda.',
      },
      {
        country: 'HH',
        start: 'Iniciar',
        back: 'Volver',
        continue: 'Continuar',
        tryAgain: 'Intentar de nuevo',
        close: 'Cerrar',
        help: 'Haga clic aquí para abrir el contenido de ayuda.',
      },
    ]

    return this.selectLanguage(content)
  }

  get prefixo() {
    let content: Prefix[] = [
      {
        country: 'BR',
        prefix: '+55',
      },
      {
        country: 'CL',
        prefix: '+56',
      },
      {
        country: 'CO',
        prefix: '+57',
      },
      {
        country: 'AR',
        prefix: '+54',
      },
      {
        country: 'BO',
        prefix: '+591',
      },
      {
        country: 'PE',
        prefix: '+51',
      },
      {
        country: 'UY',
        prefix: '+598',
      },
    ]

    return this.selectLanguage(content).prefix
  }

  apiError(error: string) {
    const listError = [
      {
        code: 'ERR001',
        BR: 'Aplicativo não cadastrado. (Cód: E0001)',
        HH: 'Aplicación no registrada. (Cód: E0001)',
      },
      {
        code: 'ERR002',
        BR: 'Usuário ou senha inválidos. (Cód: E0002)',
        HH: 'El nombre de usuario o la contraseña no son válidos. (Cód: E0002)',
      },
      {
        code: 'ERR003',
        BR: 'Link expirado. (Cód: E0003)',
        HH: 'Link se expiró. (Cód: E0003)',
      },
      {
        code: 'ERR004',
        BR: 'Não foi encontrado um registro com esse código. (Cód: E0004)',
        HH: 'No se encontró ningún registro con este código. (Cód: E0004)',
      },
      {
        code: 'ERR005',
        BR: 'Ocorreu um erro ao tentar localizar os dados. Tente novamente mais tarde. (Cód: E0005)',
        HH: 'Se generó un error al intentar buscar los datos. Vuelva a intentarlo más tarde. (Cód: E0005)',
      },
      {
        code: 'ERR006',
        BR: 'O e-mail não corresponde ao informado ao orientador. (Cód: E0006)',
        HH: 'El e-mail no corresponde al informado al orientador. (Cód: E0006)',
      },
      {
        code: 'ERR007',
        BR: 'Dados inválidos. (Cód: E0007)',
        HH: 'Datos inválidos. (Cód: E0007)',
      },
      {
        code: 'ERR008',
        BR: 'Ocorreu um erro ao tentar gravar os dados. Tente novamente mais tarde. (Cód: E0008)',
        HH: 'Ocurrió un error al intentar escribir los datos. Vuelva a intentarlo más tarde. (Cód: E0008)',
      },
      {
        code: 'ERR009',
        BR: 'Código de validação inválido. (Cód: E0009)',
        HH: 'Código de validación inválido. (Cód: E0009)',
      },
      {
        code: 'ERR010',
        BR: 'Ocorreu um erro ao tentar validar o código. Tente novamente mais tarde. (Cód: E0010)',
        HH: 'Ocurrió un error al intentar validar el código. Vuelva a intentarlo más tarde. (Cód: E0010)',
      },
      {
        code: 'ERR011',
        BR: 'Ocorreu um erro ao tentar reenviar o código. Tente novamente mais tarde. (Cód: E0011)',
        HH: 'Se produjo un error al intentar volver a enviar el código. Vuelva a intentarlo más tarde. (Cód: E0011)',
      },
      {
        code: 'ERR012',
        BR: 'Não foram encontrados dados de consentimento do hash informado. (Cód: E0012)',
        HH: 'No se encontró datos de consentimiento para el hash informado. (Cód: E0012)',
      },
      {
        code: 'ERR013',
        BR: 'Ocorreu um erro ao tentar obter os termos de consentimento. Tente novamente mais tarde. (Cód: E0013)',
        HH: 'Se produjo un error al intentar obtener los términos de consentimiento. Vuelva a intentarlo más tarde. (Cód: E0013)',
      },
      {
        code: 'ERR014',
        BR: 'Ocorreu um erro ao gravar o consentimento. Tente novamente mais tarde. (Cód: E0014)',
        HH: 'Hubo un error al escribir el consentimiento. Vuelva a intentarlo más tarde. (Cód: E0014)',
      },
      {
        code: 'ERR015',
        BR: 'Consentimento não encontrado. (Cód: E0015)',
        HH: 'Consentimiento no encontrado. (Cód: E0015)',
      },
      {
        code: 'ERR016',
        BR: 'Ocorreu um erro ao obter detalhes do consentimento. Tente novamente mais tarde. (Cód: E0016)',
        HH: 'Hubo un error al obtener los detalles del consentimiento. Vuelva a intentarlo más tarde. (Cód: E0016)',
      },
      {
        code: 'ERR017',
        BR: 'Falha ao enviar e-mail. (Cód: E0017)',
        HH: 'Error al enviar e-mail. (Cód: E0017)',
      },
      {
        code: 'ERR018',
        BR: 'Erro ao consultar Termo de Privacidade. (Cód: E0018)',
        HH: 'Error al consultar Aviso de Privacidad. (Cód: E0018)',
      },
      {
        code: 'ERR019',
        BR: 'Erro ao consultar Termo de Uso de Imagem para Crianças e Adolescentes. (Cód: E0019)',
        HH: 'Error al consultar las Condiciones de Uso de Imagen para Niños y Adolescentes. (Cód: E0019)',
      },
      {
        code: 'ERR020',
        BR: 'Erro ao consultar Termo de Uso de Imagem para Adultos. (Cód: E0020)',
        HH: 'Error consultando las Condiciones de Uso de Imagen para Adultos. (Cód: E0020)',
      },
      {
        code: 'ERR021',
        BR: 'Erro ao consultar os Termos de Matrícula. (Cód: E0021)',
        HH: 'Error al consultar las Condiciones de Matrícula. (Cód: E0021)',
      },
      {
        code: 'ERR022',
        BR: 'Documento não encontrado. (Cód: E0022)',
        HH: 'Documento no encontrado. (Cód: E0022)',
      },
      {
        code: 'ERR023',
        BR: 'Nenhum documento encontrado com essas características. (Cód: E0023)',
        HH: 'No se encontraron documentos con estas características. (Cód: E0023)',
      },
      {
        code: 'ERR024',
        BR: 'Não foi possível salvar o pin. (Cód: E0024)',
        HH: 'No se pudo guardar el pin. (Cód: E0024)',
      },
      {
        code: 'ERR025',
        BR: 'Matrícula já preenchida. (Cód: E0025)',
        HH: 'Matrícula ya completa. (Cód: E0025)',
      },
      {
        code: 'ERR026',
        BR: 'Erro ao incluir lead filho. (Cód: E0026)',
        HH: 'Error al agregar lead secundario. (Cód: E0026)',
      },
      {
        code: 'ERR027',
        BR: 'Ocorreu um erro ao finalizar o consentimento. Tente novamente mais tarde. (Cód: E0027)',
        HH: 'Hubo un error al finalizar el consentimiento. Vuelva a intentarlo más tarde. (Cód: E0027)',
      },
    ]

    let index = listError.findIndex(item => {
      return item.code === error
    })

    if (this.country === 'BR') return listError[index].BR

    return listError[index].HH
  }
}
