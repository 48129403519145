import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-question-description',
  templateUrl: './question-description.component.html',
  styleUrls: ['./question-description.component.scss']
})
export class QuestionDescriptionComponent implements OnInit {

  @Input() description = ''
  @Output() modal = new EventEmitter()

  _split!: string[]
  indexes: number[] = []
  textoInicial = ''
  textoLink = ''
  url = ''
  textoFinal = ''

  constructor() { }

  ngOnInit(): void {

    this._split = this.description.split('')

    this.findIndex()
    this.result()
  }

  result() {
    this.setTextoInical()
    this.setTextoLink()
    this.setUrl()
    this.setTextoFinal()
  }

  findIndex() {

    this._split.forEach((item, index) => {

      if (item === '[' || item === ']') {
        this.indexes.push(index)
      }

    });
  }

  setTextoInical(): void {
    this.textoInicial = this.description.substring(0, this.indexes[0])
  }

  setTextoLink(): void {
    this.textoLink = this.description.substring(this.indexes[1] + 1, this.indexes[2])
  }

  setUrl(): void {
    const startUrl = this._split.findIndex((i: string) => i === '=')

    this.url = this.description.substring(startUrl + 1, this.indexes[1])
  }

  setTextoFinal(): void {
    this.textoFinal = this.description.substring(this.indexes[3], this._split.length - 1) || '.'
  }

  abrirModal(ev: Event) {
    ev.preventDefault()
    this.modal.emit(ev)
  }

}
