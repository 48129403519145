import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from './../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private appSecretKey: string = environment.appSecretKey

  private readonly api = `${environment.API}/Authentication`

  user = {
    username: environment.username,
    password: environment.password,
  }

  constructor(
    private http: HttpClient
  ) { }

  params = new HttpParams().append('appSecretKey', this.appSecretKey)

  auth() {
    return (
      this.http.post<string>(`${this.api}/login`, this.user, { params: this.params }))
  }

  whoAmI() {
    return this.http.get<string>(`${this.api}/whoami`)
  }

}
