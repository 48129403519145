import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { Answers, SystemInformation, WebStudent } from 'src/app/models/answers.model'
import { CountryList } from 'src/app/models/country-list.model'
import { Buttons, CadastroInicial, ErrorInput, ErrorMessage } from 'src/app/models/language.model'
import { AnswersService } from 'src/app/services/answersStorage.service'
import { ApisService } from 'src/app/services/apis.service'
import { CountryListService } from 'src/app/services/country-list.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { futureDate, invalidDate, minDate, pastDate } from '../../shared/validators/form.validator'

@Component({
  selector: 'app-cadastro-inicial',
  templateUrl: './cadastro-inicial.component.html',
  styleUrls: ['./cadastro-inicial.component.scss'],
})
export class CadastroInicialComponent implements OnInit {

  lang: CadastroInicial = this.language.cadastroInicial
  langInputError: ErrorInput = this.language.errorInput
  langMessage: ErrorMessage = this.language.errorMessage
  langButton: Buttons = this.language.buttons

  country = this.dados.getDados().countryId

  botaoCarregando = false
  iconeBotaoEmail = 'fas fa-arrow-alt-circle-right'
  placeholderDate = 'dd/mm/aaaa'

  sBrowser = ''

  answers: Answers = {}
  webStudent: WebStudent = {}
  systemInfo: SystemInformation = {}

  allCountries: CountryList[] = this.countryService.allCountries
  cellphoneFormat = ''
  cellphonePlaceholder = ''

  formAluno = new FormGroup({
    fullName: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern(/[a-zA-ZÀ-ž']+\s+[a-zA-ZÀ-ž']+/)]),
    dateOfBirth: new FormControl('', [Validators.required, futureDate, pastDate, minDate, invalidDate, Validators.minLength(10)]),
    cellphone: new FormControl(),
    code: new FormControl()
  })

  constructor(
    private router: Router,
    private apiService: ApisService,
    private dados: DadosService,
    private answersStorage: AnswersService,
    private language: LanguageService,
    private countryService: CountryListService
  ) { }

  ngOnInit(): void {
    this.recuperaDados()
    this.getBrowser()
    this.changePhoneFormat(this.dados.getDados().selectedPhoneType?.isoCode || this.country)
    this.handleInvalidValues()
  }

  novaMatricula() {
    let content = {
      fullName: this.apiService.removeSpaces(this.formAluno.value.fullName, true),
      dateOfBirth: moment(this.formAluno.value.dateOfBirth, 'DD/MM/YYYY').format("YYYY-MM-DD"),
      cellphone: this.formAluno.value.cellphone,
      code: this.formAluno.value.code
    }

    this.dados.setDados(content)


    let name = this.apiService.splitName(this.apiService.removeSpaces(this.formAluno.value.fullName, true))

    this.webStudent = {
      webStudentId: '', //res.webStudentId
      centerId: this.dados.getDados().centerId,
      studentFirstName: name.firstName,
      studentMiddleName: name.middleName,
      studentLastName: name.lastName,
      studentDateOfBirth: moment(this.formAluno.value.dateOfBirth, 'DD/MM/YYYY').format("YYYY-MM-DD"),
      studentEmailAddress: this.dados.getDados().studentEmailAddress,
      urlhashCode: this.dados.getDados().hash,
      guardian: [
        {
          guardianId: 0,
          webStudentId: '', //res.webStudentId
          guardianTypeCode: "",
          guardianFirstName: "",
          guardianLastName: "",
          Cellphone: this.formAluno.value.code + this.formAluno.value.cellphone,
          guardianEmailAddress: ""
        }
      ]
    }

    this.systemInfo = {
      osname: this.verificaOS(),
      programName: this.sBrowser,
      machineName: '',
      systemInformationId: 0,
      consentId: 0, //res.consentId
      consent: ''
    }

    this.answers = {
      consentId: 0, //res.consentId
      webStudentId: '', //res.webStudentId
      signedDate: moment().toISOString(true),
      referenceCode: '',
      synchronized: true,
      webStudent: this.webStudent,
      systemInformation: [this.systemInfo]
    }

    this.answersStorage.setAnswers(this.answers)
    console.log('Answers', this.answers)

    this.router.navigate(['/enviar-codigo'])
  }

  proximaPagina() {

    if (!this.formAluno.valid) {
      this.formAluno.markAllAsTouched()
    } else {
      this.novaMatricula()
    }

  }

  paginaAnterior() {
    this.router.navigate(['/privacidade'])
  }

  handleInvalidValues() {
    if (this.dados.getDados().ksis) {
      this.formAluno.markAllAsTouched()
    }
  }

  recuperaDados() {

    if (!this.dados.hasDados()) return

    // let { length } = this.dados.getDados().selectedPhoneType?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode

    this.formAluno.setValue({
      fullName: this.dados.getDados().fullName || '',
      dateOfBirth: moment(this.dados.getDados().dateOfBirth, 'YYYY-MM-DD').format("DD/MM/YYYY") || '',
      cellphone: this.dados.getDados().cellphone || '',
      code: this.dados.getDados().selectedPhoneType?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode
    })
  }

  onSelectCountryCode(isoCode: any) {

    let selectedPhoneType = {
      isoCode: this.countryService.getInfoCountry(isoCode).isoCode,
      dialCode: this.countryService.getInfoCountry(isoCode).dialCode,
    }

    this.formAluno.patchValue({
      cellphone: ''
    })

    this.dados.setDados({ cellphone: '' })

    this.dados.setDados({ selectedPhoneType })

    this.changePhoneFormat(isoCode)
  }

  changePhoneFormat(country: string) {

    let selected = this.countryService.getInfoCountry(country)

    this.cellphoneFormat = selected.cellphone.format
    this.cellphonePlaceholder = selected.cellphone.placehoder
    this.formAluno.get('cellphone')?.setValidators([Validators.required, Validators.minLength(selected.cellphone.minLength)])
    this.formAluno.get('cellphone')?.updateValueAndValidity()

  }

  alphaOnly(e: any) {
    // let regex = new RegExp("^[a-zA-ZÀ-ž' ]+$")
    let regex = new RegExp("^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  numbersOnly(e: any) {
    let regex = new RegExp("^[0-9]*$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  verificaOS() {
    let os

    try {
      os = (window.navigator as any).userAgentData.platform
    } catch (err) {
      //console.log(err)
      os = 'undefined'
    }

    return os
  }

  getBrowser() {

    let sUsrAg = navigator.userAgent
    // The order matters here, and this may report false positives for unlisted browsers.
    if (sUsrAg.indexOf("Firefox") > -1) {
      this.sBrowser = "Mozilla Firefox"
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      this.sBrowser = "Samsung Internet"
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      this.sBrowser = "Opera"
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      this.sBrowser = "Microsoft Internet Explorer"
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      this.sBrowser = "Microsoft Edge (Legacy)"
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Edg") > -1) {
      this.sBrowser = "Microsoft Edge (Chromium)"
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      this.sBrowser = "Google Chrome"
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      this.sBrowser = "Apple Safari"
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      this.sBrowser = "Desconhecido"
    }
  }

}
