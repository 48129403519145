import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import { NgxMaskModule } from 'ngx-mask'
import { AppComponent } from './app.component'

import { NgSelectModule } from '@ng-select/ng-select'
import { ConsentInterceptor } from './authentication/authenticate.interceptor'
import { HighlightDirective } from './directives/highlight.directive'
import { CadastroInicialComponent } from './pages/cadastro-inicial/cadastro-inicial.component'
import { CheckComponent } from './pages/check/check.component'
import { ConfirmarCodigoComponent } from './pages/confirmar-codigo/confirmar-codigo.component'
import { DadosResponsavelComponent } from './pages/dados-responsavel/dados-responsavel.component'
import { EnviarCodigoComponent } from './pages/enviar-codigo/enviar-codigo.component'
import { HomeComponent } from './pages/home/home.component'
import { PrivacidadeComponent } from './pages/privacidade/privacidade.component'
import { QuestionDescriptionComponent } from './pages/termos/question-description/question-description.component'
import { TermosComponent } from './pages/termos/termos.component'
import { FilterPipe } from './pipes/filter.pipe'
import { PhonePipe } from './pipes/phone.pipe'
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe'
import { TimeFormatPipe } from './pipes/time-format.pipe'
import { HeaderComponent } from './shared/header/header.component'
import { HelpButtonComponent } from './shared/help-button/help-button.component'
import { InputErrorComponent } from './shared/input-error/input-error.component'
import { LoadingInnerComponent } from './shared/loading-inner/loading-inner.component'
import { LoadingComponent } from './shared/loading/loading.component'
import { MessageComponent } from './shared/message/message.component'
import { ModalComponent } from './shared/modal/modal.component'
import { StepComponent } from './shared/step/step.component'


// export function initializeConsent(InitServiceConsent: InitServiceConsent) {
//   return (): Promise<any> => {
//     return InitServiceConsent.Init()
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PrivacidadeComponent,
    CadastroInicialComponent,
    EnviarCodigoComponent,
    DadosResponsavelComponent,
    HeaderComponent,
    ModalComponent,
    MessageComponent,
    TermosComponent,
    StepComponent,
    InputErrorComponent,
    LoadingComponent,
    TimeFormatPipe,
    PhonePipe,
    QuestionDescriptionComponent,
    LoadingInnerComponent,
    FilterPipe,
    SanitizeHtmlPipe,
    HighlightDirective,
    HelpButtonComponent,
    ConfirmarCodigoComponent,
    CheckComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgSelectModule
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeConsent,
    //   deps: [InitServiceConsent],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConsentInterceptor,
      multi: true
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-MWRZRQC'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
