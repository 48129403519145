<a
  [href]="country === 'BR' ? linkPDF.br : linkPDF.hh"
  target="_blank"
  rel="noopener noreferrer"
>
  <div class="box-texto">
    <p>{{ langButtons.help }}</p>
  </div>
  <i class="fas fa-question-circle icone"></i>
</a>
