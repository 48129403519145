import { LanguageService } from 'src/app/services/language.service'
import { Component, OnInit } from '@angular/core'
import { Steps } from 'src/app/models/language.model'

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  lang: Steps = this.language.steps
  step: string = ''

  constructor(
    private language: LanguageService
  ) { }

  ngOnInit(): void {
  }

}
