import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { WebStudent } from 'src/app/models/answers.model'
import { Buttons, DadosResponsavel, ErrorInput, ErrorMessage } from 'src/app/models/language.model'
import { AnswersService } from 'src/app/services/answersStorage.service'
import { ApisService } from 'src/app/services/apis.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { environment } from 'src/environments/environment'
import { Guardian } from './../../models/answers.model'

@Component({
  selector: 'app-dados-responsavel',
  templateUrl: './dados-responsavel.component.html',
  styleUrls: ['./dados-responsavel.component.scss']
})
export class DadosResponsavelComponent implements OnInit {

  lang: DadosResponsavel = this.language.dadosResponsavel
  langInputError: ErrorInput = this.language.errorInput
  langButtons: Buttons = this.language.buttons
  langMessage: ErrorMessage = this.language.errorMessage

  placeholderEmailConf = this.langInputError.confirmEmail
  txtDadosErrados = ''

  botaoCarregando = false
  botaoAtivo = false
  iconeBotaoEmail = "fas fa-arrow-alt-circle-right"
  emailConfValido = true
  hasDadosAluno = true
  setDadosAlunoChecked = false
  isDadosResponsavel = true

  protocolo: string = ''
  country = ''

  hash = ''
  email = ''

  private readonly linkEnrollment = `${environment.urlEnrollment}`
  private readonly linkEnrollmentLA = `${environment.urlEnrollmentLA}`

  webStudent: WebStudent = {}
  guardian: Guardian = {}

  formCadastro = new FormGroup({
    nome: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern(/[a-zA-Z]+\s+[a-zA-Z]+/)]),
    email: new FormControl('', [Validators.email, Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    emailConfirm: new FormControl('', [Validators.email, Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
  })


  constructor(
    private router: Router,
    private api: ApisService,
    private dados: DadosService,
    private answersStorage: AnswersService,
    private language: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {

    this.getInfo()
    this.country = this.dados.getDados().countryId

    console.log('country', this.country)
  }


  getInfo() {

    if (!this.dados.hasDados()) return
    this.hash = this.dados.getDados().hash
    this.email = this.dados.getDados().studentEmailAddress

    this.formCadastro.patchValue({
      email: this.email,
      emailConfirm: this.email
    })

  }

  getProtocolo() {

    if (this.dados.getDados().protocolo !== undefined) {

      this.protocolo = this.dados.getDados().protocolo

      this.irParaMatricula()

    } else {

      this.api.finish(this.hash, this.country).subscribe(
        res => {
          this.dados.setDados({ protocolo: res.text })
          this.protocolo = res.text
          //this.isLoading = false

          this.irParaMatricula()
        },
        err => {

          if (err.error.text !== undefined || err.error.text !== '') {
            this.dados.setDados({ protocolo: err.error.text })
            this.protocolo = err.error.text
            this.irParaMatricula()
          }

          this.txtDadosErrados = this.language.apiError(err.error)
        }
      )
    }

  }

  enviaResposta() {

    this.iconeBotaoEmail = "fas fa-spinner"
    this.botaoCarregando = true

    let name = this.api.splitName(this.api.removeSpaces(this.formCadastro.value.nome, true))

    this.guardian = {
      guardianId: 0,
      guardianTypeCode: "",
      guardianFirstName: name.firstName,
      guardianMiddleName: name.middleName,
      guardianLastName: name.lastName,
      Cellphone: this.dados.getDados().cellphone,
      guardianEmailAddress: this.api.removeSpaces(this.formCadastro.value.email),
    }

    if (this.answersStorage.hasAnswers()) {
      this.webStudent = this.answersStorage.getAnswers().webStudent
    }

    let completeGuardian = this.webStudent.guardian?.map(obj => {
      return {
        ...obj,
        ...this.guardian
      }
    })

    let dados = {
      webStudent: {
        ...this.webStudent,
        guardian: completeGuardian
      }
    }

    this.answersStorage.setAnswers(dados)

    this.api.answers(this.answersStorage.getAnswers()).subscribe(
      res => {

        if (res) {

          this.getProtocolo()
        }

        console.log(this.answersStorage.getAnswers())

      },
      err => {
        this.txtDadosErrados = this.language.apiError(err.error)
        this.isDadosResponsavel = false
        this.iconeBotaoEmail = "fas fa-arrow-alt-circle-right"
        this.botaoCarregando = false
      }
    )
  }

  paginaAnterior() {
    return this.dados.getDados().hasConditions
      ? this.router.navigate(['/termos'])
      : this.router.navigate(['/cadastro-inicial'])
  }

  proximaPagina() {

    if (this.validaEmail() && this.formCadastro.valid) {
      return this.enviaResposta()
    } else {
      this.formCadastro.markAllAsTouched()
    }

  }

  irParaMatricula() {
    sessionStorage.clear()
    // this.document.location.href = `${this.linkEnrollment}/?hash=${this.hash}&e=${this.email}`

    if (this.country === 'BR') {
      this.document.location.replace(`${this.linkEnrollment}/?hash=${this.hash}&email=${this.email}`)
    } else {
      this.document.location.replace(`${this.linkEnrollmentLA}/?hash=${this.hash}&email=${this.email}`)
    }
  }

  validaEmail(): boolean {

    let email = this.api.removeSpaces(this.formCadastro.value.email)
    let confirmEmail = this.api.removeSpaces(this.formCadastro.value.emailConfirm)

    if (email === confirmEmail) {
      this.emailConfValido = true
      return true
    } else {
      this.emailConfValido = false
      return false
    }
  }

  limpaErro() {
    this.emailConfValido = true
  }

  fecharErro() {
    this.isDadosResponsavel = true
  }

  alphaOnly(e: any) {
    let regex = new RegExp("^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

}
