<app-step></app-step>

<div class="k-container">
  <div class="principal" [ngSwitch]="isDadosResponsavel">
    <div *ngSwitchCase="true">
      <h4 class="titulo">{{ lang.title }}</h4>

      <p class="textos">
        {{ lang.text }}
      </p>

      <form [formGroup]="formCadastro">
        <div class="ipt">
          <label class="ipt__label"> {{ lang.labelFullName }} </label>
          <input
            class="ipt__campo"
            formControlName="nome"
            type="text"
            maxlength="40"
            (paste)="$event.preventDefault()"
            (keypress)="alphaOnly($event)"
            [placeholder]="lang.placeholderFullName"
          />
          <div
            *ngIf="
              formCadastro.get('nome')?.invalid &&
              (formCadastro.get('nome')?.dirty ||
                formCadastro.get('nome')?.touched)
            "
          >
            <app-input-error
              *ngIf="formCadastro.get('nome')?.errors?.['required']"
              [textError]="langInputError.required"
            ></app-input-error>
            <app-input-error
              *ngIf="formCadastro.get('nome')?.errors?.['pattern']"
              [textError]="langInputError.incompleteFullName"
            ></app-input-error>
          </div>
        </div>

        <div class="info-email">
          <div class="info-email__title">
            <i class="fas fa-info-circle"></i>
            <p>{{ lang.titleInfo }}</p>
          </div>
          <span> {{ lang.textInfo }}</span>
        </div>

        <div class="ipt ipt-emailResp">
          <label class="ipt__label"> {{ lang.labelEmail }} </label>
          <input
            class="ipt__campo"
            formControlName="email"
            type="email"
            autocapitalize="none"
          />
          <div
            *ngIf="
              formCadastro.get('email')?.invalid &&
              (formCadastro.get('email')?.dirty ||
                formCadastro.get('email')?.touched)
            "
          >
            <app-input-error
              *ngIf="formCadastro.get('email')?.errors?.['required']"
              [textError]="langInputError.required"
            ></app-input-error>
            <app-input-error
              *ngIf="formCadastro.get('email')?.errors?.['pattern']"
              textError="E-mail inválido."
            ></app-input-error>
          </div>
        </div>

        <div class="ipt">
          <label class="ipt__label"> {{ lang.labelConfirmEmail }} </label>
          <input
            class="ipt__campo"
            formControlName="emailConfirm"
            type="email"
            autocapitalize="none"
            (paste)="$event.preventDefault()"
            (contextmenu)="$event.preventDefault()"
            (input)="limpaErro()"
          />
          <app-input-error
            [textError]="placeholderEmailConf"
            *ngIf="!emailConfValido"
          ></app-input-error>
          <div
            *ngIf="
              formCadastro.get('emailConfirm')?.invalid &&
              (formCadastro.get('emailConfirm')?.dirty ||
                formCadastro.get('emailConfirm')?.touched)
            "
          >
            <app-input-error
              *ngIf="formCadastro.get('emailConfirm')?.errors?.['required']"
              [textError]="langInputError.required"
            ></app-input-error>
          </div>
        </div>

        <div class="navigate-button">
          <button class="botaoVoltar" type="button" (click)="paginaAnterior()">
            <i class="fas fa-arrow-alt-circle-left"></i>
            <span> {{ langButtons.back }} </span>
          </button>

          <button
            class="botaoContinuar"
            [class.btnCarregando]="botaoCarregando"
            [class.btnInativo]="!formCadastro.valid"
            (click)="proximaPagina()"
          >
            <span> {{ langButtons.continue }} </span>
            <i [class]="iconeBotaoEmail"></i>
          </button>
        </div>
      </form>
    </div>

    <app-message
      *ngSwitchCase="false"
      icon="fas fa-exclamation"
      colorIcon="error"
      [titulo]="langMessage.titleMessageError"
      class="containerMessageError"
    >
      <p>{{ txtDadosErrados }}</p>
      <button type="button" class="btnMain" (click)="fecharErro()">
        {{ langButtons.tryAgain }}
      </button>
    </app-message>
    <app-help-button *ngSwitchCase="true"></app-help-button>
  </div>
</div>
