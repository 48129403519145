<app-step></app-step>

<div class="k-container">
  <div class="principal">
    <div>
      <h4 class="titulo">{{ lang.title }}</h4>
    </div>

    <p class="label" [ngSwitch]="sendMethod">

      <ng-container *ngSwitchCase="'email'">
        {{ lang.labelEmail }}
        <span class="destaque">{{ email }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'sms'">
        {{ lang.labelSMS }}
        <span class="destaque">{{ cellphone }}</span>
      </ng-container>
      
      {{lang.labelBelow}}
    </p>

    <div class="wrapper-codigo">

      <div class="codigo">
        <input
          type="text"
          max="6"
          mask="000 000"
          [formControl]="smsInput"
          (focus)="limpaMensagemErro()"
          inputmode="numeric" />
        <app-input-error
          *ngIf="!smsValido"
          [textError]="langInputError.invalidSMS"></app-input-error>
      </div>

      <div>
        <button
          class="btnReenviar"
          [disabled]="!isReenviar"
          [ngSwitch]="isReenviar"
          (click)="reenviar()">
          <span *ngSwitchCase="false">
            {{ lang.countDownSMS }}
            <span class="timerBox">{{ timer | timeFormat }}</span></span>
          <span *ngSwitchCase="true"> {{ lang.resendSMS }} </span>
        </button>
        <app-input-error
          [textError]="textResendError"
          *ngIf="resendError"></app-input-error>
      </div>
    </div>

    <div class="navigate-button">
      <button class="botaoVoltar" (click)="paginaAnterior()" [disabled]="isBackButtonDisabled">
        <i class="fas fa-arrow-alt-circle-left"></i>
        <span> {{ langButtons.back }} </span>
      </button>
      <button
        class="botaoContinuar"
        [class.btnCarregando]="botaoCarregando"
        [class.btnInativo]="smsInput.status == 'INVALID'"
        (click)="proximaPagina()">
        <span> {{ langButtons.continue }} </span>
        <i [class]="iconeBotao"></i>
      </button>
    </div>
  </div>

  <app-help-button></app-help-button>